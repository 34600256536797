import React, { useState, useRef, useEffect } from 'react'
import { FaBars } from 'react-icons/fa'
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";

import './MyNavbar.css';
import Weather from '../Weather/Weather';


const MyNavbar = () => {
  const [showLinks, setShowLinks] = useState(false);
  const linksContainerRef = useRef(null);
  const linksRef = useRef(null);
  
  const weather = Weather();
  
  useEffect(() => {
    const linksHeight = linksRef.current.getBoundingClientRect().height;
    if (showLinks) {
      linksContainerRef.current.style.height = `${linksHeight}px`
    } else {
      linksContainerRef.current.style.height = '0px'
    }
  }, [showLinks])
  
  return (
    <nav>
      <div className="nav-center">
        <div className="nav-header">
          <Link to='/' className="nav_logo">
            <h1 className="Logo_B">B</h1>
            <h1 className="Logo_Rest">abette</h1>
            <h1 className="Logo_B">B</h1>
            <h1 className="Logo_Rest">logt</h1>
          </Link>
          <div className="LinksAndWeather">
            <div className="weather_Phone">
              <p><span data-tip="De temperatuur <br/> in Uppsala">{weather.temperature}℃</span></p>
              <img className='weatherIcon' src={weather.icon.default} alt={weather.iconName} />
            </div>
            <button className="nav-toggle" onClick={() => setShowLinks(!showLinks)}>
              <FaBars />
            </button>
            <ReactTooltip data-place="bottom" multiline={true} effect='solid'/>
          </div>
        </div>
        <div className="LinksAndWeather">
          <div className='weather'>
            <p><a className='NavUppsala' href="https://www.yr.no/en/forecast/daily-table/2-2666199/Sweden/Uppsala/Uppsala%20Municipality/Uppsala" target="_blank" rel="noreferrer">Uppsala:</a> {weather.temperature}℃</p>
            <img className='weatherIcon' src={weather.icon.default} alt={weather.iconName} />
          </div>
          <div className="links-container" ref={linksContainerRef}>
            <ul className="links" ref={linksRef}>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/blogs">Blogs</Link>
              </li>
              <li>
                <Link to="/over_mij">Over Mij</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
      
    </nav>


  )
};
export default MyNavbar
