import '../Page.css';
import './Blog.css';

import React from 'react';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import { useEffect } from 'react';
import ReactPlayer from 'react-player'

import Dinner from "../../img/Posts/Post5.mp4"

import Train1 from "../../img/Posts/Post5_1.jpg"
import Train2 from "../../img/Posts/Post5_2.jpg"

import Snowscooter1 from "../../img/Posts/Post5_3.jpg"
import Snowscooter2 from "../../img/Posts/Post5_4.jpg"

import Cabin1 from "../../img/Posts/Post5_5.jpg"
import Cabin2 from "../../img/Posts/Post5_6.jpg"
import Cabin3 from "../../img/Posts/Post5_7.jpg"
import Cabin4 from "../../img/Posts/Post5_8.jpg"

import Snow1 from "../../img/Posts/Post5_9.jpg"
import Snow2 from "../../img/Posts/Post5_10.jpg"
import Snow3 from "../../img/Posts/Post5_11.jpg"
import Snow4 from "../../img/Posts/Post5_12.jpg"
import Snow5 from "../../img/Posts/Post5_13.jpg"
import Snow6 from "../../img/Posts/Post5_15.jpg"
import Snow7 from "../../img/Posts/Post5_14.jpg"

import Sauna2 from "../../img/Posts/Post5_16.jpg"
import Sauna3 from "../../img/Posts/Post5_17.jpg"
import Sauna4 from "../../img/Posts/Post5_18.jpg"
import Sauna5 from "../../img/Posts/Post5_19.jpg"
import Sauna6 from "../../img/Posts/Post5_20.jpg"

import map from "../../img/Posts/Post5_21.jpg"

const customArrows = {
  prevArrow: <div style={{width: "30px", marginRight: "-30px"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="hsl(205, 78%, 60%)"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/></svg></div>,
  nextArrow: <div style={{width: "30px", marginLeft: "-30px"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="hsl(205, 78%, 60%)"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/></svg></div>
}
const Title = "Li-La-Lapland (Deel 1)";

function Blog5() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id='Blog_19-3-22' className="Blog Page">
      <Helmet>
        <title>{Title}</title>
      </Helmet>
      <h1>{Title}</h1>
      <h3 className='Date'>19 maart 2022</h3>
      <hr/>
      <p>
        Van een sneeuwscooter besturen, naar afkoelen in een ijskoud meer na de sauna, tot het noorderlicht zien. Afgelopen weekend heb ik een van de meest bijzondere 
        weekenden van mijn leven meegemaakt. Een weekend naar Zweeds Lapland! Het was magisch. Ik heb tijdens de trip niet veel geslapen, maar ik ben geen moment echt 
        moe geweest. Mijn lichaam zat constant vol met adrenaline en ik had een soort permanente glimlach op mijn gezicht. In dit blog een reisverslag van de eerste 
        twee dagen van dit weekend, want ik heb zoveel te vertellen, dat past niet in één blogpost. 
      </p>

      <h3>Donderdag 10 maart</h3>
      <p>
        19.02 donderdagavond, vertrekken wij vanaf Uppsala centraal station met de nachttrein naar Kiruna, de grootste stad van Zweeds Lapland. Ik ben samen met Kajsa, 
        Tabea, Karo, en Lara. Met Kajsa, Tabea en Karo (mijn Vlaamse huisgenoot) ga ik al langer goed om, alleen Lara (Nederlands) kent eigenlijk nog niemand van ons 
        voor deze trip. Dat is best typerend voor het leven van een internationale student, het is altijd des te meer zielen, des te meer vreugd!
      </p>
      <p>
        Op het station klikt het meteen met Lara, dus dat is al een goed begin van onze reis. In de nachttrein hebben wij 
        een <span data-tip="Een coupé met bedden" className='Tooltip_Word'>couchette</span> geboekt, dus: een bed om in te slapen! Het is een redelijk kleine cabine die 
        wij nog met een andere Oostenrijkse studente delen. Het is eerst even uitzoeken hoe wij deze cabine het beste kunnen indelen met alle bagage die we mee hebben, 
        maar na wat gestuntel en veel gelach, hebben wij ons dan eindelijk geïnstalleerd.
      </p>
      <p>
        De reis verloopt voorspoedig. Hoewel het donker is buiten, is het toch mogelijk om iets van het landschap te zien. Net voordat we gaan slapen, zien Karo en ik 
        zelfs nog kort het noorderlicht vanuit de trein! Ik kan mijn ogen moeilijk van het raam afhouden, maar het mooiste begint eigenlijk de volgende ochtend pas. Al 
        vanuit mijn bed kan ik zien hoe wij ons door het inmiddels hagelwitte landschap verplaatsen. Dat geeft mij zoveel energie dat ik ondanks de korte nacht, zo naast 
        mijn bed sta. 
      </p>

      <div className="slide-container">
        <Slide {...customArrows} autoplay={false} indicators={true}>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Train1} alt="In de nachttrein!" />
            <p className="subtitle">In de nachttrein!</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Train2} alt="De nachttrein op Kiruna station" />
            <p className="subtitle">De nachttrein op Kiruna station</p>
          </div>
        </Slide>
      </div>

      <h3>Vrijdag 11 maart</h3>
            
      <p>
        Na een reis van meer dan veertien uur stappen wij vrijdagochtend om 9:22 uit op het station van Kiruna. We hebben hier meteen een mooi uitzicht op de skipiste van 
        de stad. Een aantal bussen en mensen met naambordjes staan ons net buiten het station op te wachten en na even zoeken vinden wij het bordje dat 
        zegt: <i>Taube Activity</i>, de organisatie waarbij wij onze eerste overnachting in de natuur hebben geboekt. 
      </p>
      <p>
        Na even gewacht te hebben op onze andere groepsgenoten, rijdt Märta, onze gids, ons naar een plek buiten Kiruna. De autorit duurt ongeveer 35 minuten en we zijn 
        al snel buiten de stad en in de Laplandse natuur. Wij rijden over besneeuwde wegen door de bossen en tijdens deze rit zie ik al een rendier achter een boom zitten! 
        Wij stoppen uiteindelijk aan het einde van een doodlopende weg. Als we uitstappen zien we sneeuwscooters, een keet en een groep met andere reizigers staan. 
      </p>
      <p> 
        Heel toevallig zie ik daar Philip staan, een Duitse jongen die ook in Uppsala studeert en lid is bij dezelfde nation als ik. Dat is dus meteen al gezellig en het 
        blijkt snel dat het sowieso een leuke groep is. De hele groep bestaat uit studenten, dus dat geeft gelijk een leuke sfeer. Märta vertelt ons dat wij zo met 
        sneeuwscooters, die wij zelf mogen besturen, naar ons ‘kamp’ midden in de natuur gaan. Voordat we vertrekken, hebben we de mogelijkheid om onze schoenen om te 
        ruilen voor echte <i>snowboots</i>, een skibroek te lenen en/of onze overtollige bagage achter te laten in de keet. 
      </p>
      <p> 
        Na ongeveer een half uur zijn wij allemaal klaar om te gaan! Een paar mensen gaan de sneeuwscooters besturen en de rest zit achter de sneeuwscooters op sleeën die 
        door de sneeuwscooters getrokken worden. En dan begint het. Een betoverende rit door het witte landschap. We zien besneeuwde heuvels en bevroren meren (als je je 
        überhaupt beseft dat die open witte vlakte waarop je rijdt een meer is). Tijdens de rit stoppen wij af en toe om te wisselen van sneeuwscooterbestuurder. Ik rij 
        niet op de heenweg, maar ons is beloofd dat wij mogen rijden op de terugweg.
      </p>

      <div className="slide-container">
        <Slide {...customArrows} autoplay={false} indicators={true}>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Snowscooter1} alt="Tijdens de sneeuwscooter rit!" />
            <p className="subtitle">Tijdens de sneeuwscooter rit!</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Snowscooter2} alt="Achter op de slee!" />
            <p className="subtitle">Achter op de slee!</p>
          </div>
        </Slide>
      </div>
      <hr style={{maxWidth:"40%"}}/>
      <div className="imgDiv">
        <img src={map} alt="De afgelegde route" />
        <p className="subtitle">De afgelegde route</p>
      </div>

      <p>
        Tien kilometer later komen wij aan bij onze slaapplaats. Wij stoppen aan de rand van een bevroren meer, voor de sauna. Na ons afgezet te hebben, sneeuwscootert 
        Märta een heuvel op met onze bagage. Wij volgen haar en komen aan bij een gezellig ‘kamp’ midden in het bos. Märta laat ons zien waar de hutten zijn, geeft ons 
        instructies hoe en waar we hout moeten hakken en legt ons uit waar het toilet is. 
      </p>

      <p>
        Op dit kamp hebben wij geen elektriciteit of stromend water. De hut houden we dus warm met een houtvuurtje en water halen we uit het meer. Dit water is zo schoon 
        dat het niet gefilterd hoeft te worden. Na Märta haar uitleg, installeren wij ons in het kamp. We hakken hout, halen water en maken vuur. Wij delen onze hut met 
        zes anderen.
      </p>

      <p>
        Wij hebben deze reis alleen soms wel wat pech. Nog voor de soep-met-rendiervlees-lunch gaan een paar van ons sleeën en tijdens het sleeën komt Lara naar terecht 
        op haar rug. Ze heeft meteen veel pijn en kan helaas de rest van de middag niet meer met activiteiten meedoen. Gelukkig lijkt het, afgezien van de pijn, mee te vallen.
      </p>

      <div className="slide-container">
        <Slide {...customArrows} autoplay={false} indicators={true}>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Cabin1} alt="De hutten waar wij in sliepen" />
            <p className="subtitle">De hutten waar wij in sliepen</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Cabin2} alt="Tabea die het ijs weghakte zodat wij water uit het meer konden halen" />
            <p className="subtitle">Tabea die het ijs weghakte zodat wij water uit het meer konden halen</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Cabin3} alt="De hut waar wij lunch aten" />
            <p className="subtitle">De hut waar wij lunch aten</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Cabin4} alt="De soep-met-rendiervlees-lunch!" />
            <p className="subtitle">De soep-met-rendiervlees-lunch!</p>
          </div>
        </Slide>
      </div>

      <p>
        Na de lunch gaan we langlaufen op het meer! Ik dacht altijd dat dit best wel makkelijk was, de atleten op TV bewegen namelijk altijd heel soepel op de ski’s. Maar 
        het blijkt verrassend moeilijk en vermoeiend. Als we uit gelanglauft zijn, lopen Karo en ik een stuk langs de rivier die naar het meer stroomt. Het is goed 
        uitkijken, want wanneer je één stap naast het pad zet, zak je tot je heupen in de sneeuw. Gelukkig zijn we allemaal goed ingepakt met skibroeken en snowboots, 
        dus is het vooral erg grappig.
      </p>
      <p>
        Karo en ik eindigen onze wandeling op het meer samen met Kajsa. De zon is dan net onder gegaan en de lucht is heel mooi roze. Het is echt sprookjesachtig. We 
        kunnen hier ‘helaas’ niet oneindig van blijven genieten. Enerzijds omdat het natuurlijk donker wordt, maar anderzijds omdat we om 18:00 worden verwacht voor het 
        diner. We gaan barbecueën!
      </p>

      <div className="slide-container">
        <Slide {...customArrows} autoplay={false} indicators={true}>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Snow1} alt="Langlaufen" />
            <p className="subtitle">Langlaufen</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Snow2} alt="Wandelen met Karo langs de rivier" />
            <p className="subtitle">Wandelen met Karo langs de rivier</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Snow3} alt="Karo en ik" />
            <p className="subtitle">Karo en ik</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Snow4} alt="Tijdens onze 'avondwandeling'" />
            <p className="subtitle">Tijdens onze 'avondwandeling'</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Snow5} alt="Kajsa en ik kijkend naar mooie lucht" />
            <p className="subtitle">Kajsa en ik kijkend naar mooie lucht</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Snow6} alt="Zonsondergang met de sauna" />
            <p className="subtitle">Zonsondergang met de sauna</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Snow7} alt="De rivier net achter onze hutten" />
            <p className="subtitle">De rivier net achter onze hutten</p>
          </div>
        </Slide>
      </div>

      <p>
        In een schattig klein hutje heeft Märta een vuurtje aangestoken en om de beurten gaan wij hier eten. Wij kunnen een Zweedse worst (waarvan ik de naam ben vergeten), 
        mais en <span data-tip="Grillkaas" className='Tooltip_Word'>halloumi</span> grillen en Märta heeft aardappelpuree en bonen voor ons bereid. Het is erg knus en 
        gezellig en het is echt even een moment om tot rust te komen na de lange dag. 
      </p>

      <div className="imgDiv">
        <ReactPlayer 
          style={{maxWidth:"60ch", maxHeight:"25rem", width:"inherit", height:"inherit"}}
          url={Dinner}
          controls={true}
          muted={true}
        />
        <p className="subtitle">Avondeten!</p>
      </div>
      
      <p>
        Maar echt ontspannen doe ik pas later op de avond, dan gaan we namelijk in de sauna! De sauna staat op het meer en heeft een raam, dus vanuit de sauna heb je een 
        prachtig uitzicht. Met onze bikini onder onze jas en skibroek, lopen wij snel naar de sauna toe. In het begin is de sauna nog niet super warm, maar nadat we het 
        vuurtje hebben opgestookt en sneeuw op de stenen hebben gegooid, beginnen we toch echt goed te zweten. 
      </p>
      <p>
        Na een tijdje wordt het mij toch echt te warm in de sauna en besluit ik om er uit te gaan. Om af te koelen is het in principe al genoeg om buiten te staan, want 
        het is onder nul, maar dat is natuurlijk niet zo heel spannend. In het ijs is namelijk ook een gat gemaakt om water uit te halen, of om in af te koelen na de sauna…
      </p>
      <p>
        Wanneer ik de sauna uit kom, trek ik mijn schoenen aan en ren ik naar dit gat toe. Een Italiaanse meid helpt mij om de ladder vast te houden en ik ga het ijskoude 
        water in! Het is echt koud en het duurt misschien 10 seconden voordat mijn lichaam begint te protesteren en ik weer uit het water klim. Maar het was verrassend 
        fijn. Het kamp heeft ook geen stromend water dus op deze manier kun je meteen het zweet van jezelf afspoelen. Na mij gaat de rest ook in het ijskoude water. 
      </p>
      <p>
        En daarna gaan we voor ronde twee! En deze eindigen we ook weer met een ‘duik’ in het water. Na deze ronde wachten we buiten voor de sauna om onze spullen te kunnen 
        pakken. En terwijl we wachten, kijk ik naar boven en zie daar het noorderlicht! Eerst een klein beetje, maar het wordt langzaamaan steeds meer en steeds intenser! 
        De kou doet er even niet meer toe en iedereen is door het dolle heen. Het is een hele mooi kers op een al bijzondere dag. 
      </p>

      <div className="slide-container">
        <Slide {...customArrows} autoplay={false} indicators={true}>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Sauna2} alt="Afkoelen na de sauna in het meer!" />
            <p className="subtitle">Afkoelen na de sauna in het meer!</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Sauna3} alt="Noorderlicht en rechts de sauna waar we net uitkwamen" />
            <p className="subtitle">Noorderlicht en rechts de sauna waar we net uitkwamen</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Sauna4} alt="Nog meer noorderlicht" />
            <p className="subtitle">Nog meer noorderlicht</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Sauna5} alt="Het noorderlicht was pal boven ons en bewoog magisch" />
            <p className="subtitle">Het noorderlicht was pal boven ons en bewoog magisch</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Sauna6} alt="De binnenkant van de hut waar wij sliepen! Ik sliep op het onderste bed aan de linkerkant" />
            <p className="subtitle">De binnenkant van de hut waar wij sliepen! <br />Ik sliep op het onderste bed aan de linkerkant</p>
          </div>
        </Slide>
      </div>

      <p>
        Terug in de hut maken we plannen om de volgende ochtend om 6:00 op te staan om de zonsopkomst te zien en eventueel dieren te spotten! Maar wat wij zaterdag en zondag 
        hebben meegemaakt, bewaar ik voor een ander blog. Deze trip was namelijk zo bijzonder dat ik graag álles wil vertellen en jullie geen detail wil besparen. Maar voor 
        nu is deze blog wel lang genoeg geworden. Wordt vervolgd! 
      </p>
      
      <p>Hej då, <br />Babette </p>
      <div className="prevNextButs">
        <Link id='nextBlog' className='Link' to="/blogs/blog_31-5-22"> Volgende Blog: <br /><span>Ik leef nog!</span> </Link>
        <Link id='prevBlog' className='Link' to="/blogs/blog_6-3-22"> Vorige Blog: <br /><span>Eat, sing, drink, repeat</span></Link>  
      </div>

      <ReactTooltip offset={{top: -10}} effect='solid'/>
    </div>
  );
}

export default Blog5;