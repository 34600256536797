import { Routes, Route } from "react-router-dom";

import Welcome from './Pages/Welcome';
import OverMij from './Pages/OverMij';
import NoPage from './Pages/NoPage';
import Blogs from './Pages/Blogs';
import Layout from './Pages/Layout';

//Posts
import Blog1 from './Pages/Posts/Blog_22-1-9';
import Blog2 from './Pages/Posts/Blog_22-1-20';
import Blog3 from './Pages/Posts/Blog_22-2-1';
import Blog4 from './Pages/Posts/Blog_22-3-6';
import Blog5 from './Pages/Posts/Blog_22-3-19';
import Blog6 from './Pages/Posts/Blog_22-5-31';
import Blog7 from './Pages/Posts/Blog_22-9-28';

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route index element={<Welcome />} />
        <Route path="/blogs" element={<Blogs />}/>
        <Route path="/blogs/blog_9-1-22" element={<Blog1 />}/>
        <Route path="/blogs/blog_20-1-22" element={<Blog2 />}/>
        <Route path="/blogs/blog_1-2-22" element={<Blog3 />}/>
        <Route path="/blogs/blog_6-3-22" element={<Blog4 />}/>
        <Route path="/blogs/blog_19-3-22" element={<Blog5 />}/>
        <Route path="/blogs/blog_31-5-22" element={<Blog6 />}/>
        <Route path="/blogs/Blog_28-9-22" element={<Blog7 />}/>
        <Route path="/over_mij" element={<OverMij />} />
        <Route path="*" element={<NoPage />} />
      </Route>
    </Routes>
  );
}

export default App;
