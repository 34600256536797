import Error404 from '../img/404.png'
import { Helmet } from 'react-helmet';

function NoPage() {
  return (
    <div className="Error404" style={{
      width: "100%",
      height: "92%",
      backgroundColor: "white",
      overflow: "hidden",
      textAlign: "center",
      position: "Absolute"
    }}>
      <Helmet>
        <title>Verkeerde pagina - Babette Blogt!</title>
      </Helmet>
      <img src={Error404} alt="404" style={{
          paddingTop: "2rem",
          maxWidth: "100%"      
      }}/>
    </div>
  );
}

export default NoPage;