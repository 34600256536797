import { useState, useEffect } from 'react';
const sitename = "https://babetteblogt.nl";
const Useragent = {
  "User-Agent" : sitename
}

export default function Weather() {
  const [temperature, setTemperature] = useState("");
  const [icon, setIcon] = useState("");
  const [iconName, setIconName] = useState("");
  
  useEffect(() => {
    const expire = new Date(localStorage.getItem('Weather-Expiration'));
    if(expire===null||expire<=new Date(Date.now())){
      fetch("https://api.met.no/weatherapi/locationforecast/2.0/compact?lat=59.858&lon=17.638", { 
        "method": "GET",
        Useragent
      })
        .then(res => {
          return res.json()
        })
        .then(data => {
          setTemperature(data.properties.timeseries[0].data.instant.details.air_temperature); 
          setIcon(require(`../Weather/icons/${data.properties.timeseries[0].data.next_1_hours.summary.symbol_code}.svg`));
          setIconName(data.properties.timeseries[0].data.next_1_hours.summary.symbol_code);
          localStorage.setItem('Weather', data.properties.timeseries[0].data.instant.details.air_temperature);
          localStorage.setItem('Weather-Icon', data.properties.timeseries[0].data.next_1_hours.summary.symbol_code);
          localStorage.setItem('Weather-Expiration', new Date(Date.now()+1800000).toISOString());
        })
      }else{
        setTemperature(localStorage.getItem('Weather'));
        setIcon(require(`../Weather/icons/${localStorage.getItem('Weather-Icon')}.svg`));
        setIconName(localStorage.getItem('Weather-Icon'));
      }
    }, [])
    return ({temperature, icon, iconName});
}