import './Page.css';
import Profile from '../img/Profile.jpg';
import Profile2 from '../img/Profile2.jpg';

import { useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactPlayer from 'react-player'

import Sunset from '../Vids/Sunset.mp4'

let counter = 1;

function OverMij() {
  const ProfileClick = () => {
    if(counter++>=5){
      console.log("count: " +counter);
      if(EasterEgg===Profile){
        SetEasterEgg(Profile2);
      }else{
        SetEasterEgg(Profile);
      }
    }
    console.log("profile click");
  }

  const [EasterEgg,SetEasterEgg] = useState([Profile]);

  return (
    <div id="OverMij" className='Page'>
      <Helmet>
        <title>Over Mij - Babette Blogt!</title>
      </Helmet>
      <h1>Over Mij</h1>
      <p>Hoi! Wat leuk dat je op mijn blog bent! Ik ben Babette, 21 jaar en een vierdejaars politicologie student aan de Universiteit van Amsterdam. Aankomend 
        semester (januari 2022) ga ik op uitwisseling naar Zweden! Aan de Universiteit van Uppsala ga ik een aantal vakken in de politicologie volgen en Zweeds leren.</p>
      <div className="imgDiv">
        <hr className='small'/>
        <img onClick={ProfileClick} src={EasterEgg} alt="Een foto van mij" />
        <hr className='small'/>
      </div>
      <p>
        Op dit moment woon ik in Nederland nog bij mijn ouders in een dorp. Ik heb lang gevoetbald, speel piano en probeer mijzelf ukelele te leren spelen. Ik hou 
        dus van sport(en) en muziek luisteren en maken. Daarnaast breng ik graag tijd door met familie en vrienden, maar vind het ook fijn om af en toe alleen te 
        zijn om bijvoorbeeld te lezen.
      </p>
      <br />
      <p>
        Ik ben al lange tijd fan van Scandinavië. Op mijn 11e gingen wij voor het eerst op vakantie naar Noorwegen, Zweden en Denemarken en ik vond het fantastisch. 
        Op mijn 17e gingen wij nog een keer naar Noorwegen en Zweden en dit is denk ik nog steeds mijn meest favoriete vakantie. De natuur, de rust en de sfeer, ik 
        wilde eigenlijk niet meer naar huis.
      </p>
      <br />
      <p>
        Toen ik dus in het derde jaar van mijn studie de kans kreeg om in het buitenland te studeren, hoefde ik niet lang na te denken waar ik heen zou willen: 
        Scandinavië! De vraag was alleen nog maar naar welke universiteit. Uiteindelijk kwam ik op Uppsala uit, een kleine stad boven Stockholm. Hier ga ik dus de 
        komende vijf maanden studeren!
      </p>
      <div className="imgDiv">
        <ReactPlayer 
          style={{maxWidth:"60ch", maxHeight:"20rem"}}
          url={Sunset}
          controls={false}
          loop={true}
          muted={true}
          playsinline={true}
          playing={true}
          width={"fit-content"}
          height={"fit-content"}
        />
      </div>
      <p>
        Naast dat ik enthousiast word van Scandinavië, politiek en voetbal, word ik ook enthousiast van schrijven. Daarom heb ik besloten om een blog bij te houden 
        over alles wat ik tijdens mijn uitwisseling (en daarom heen) meemaak! Ik hoop dat jullie mij blijven volgen!
      </p>
      <br />
    </div>
  );
}

export default OverMij;
