import '../Page.css';
import './Blog.css';

import React from 'react';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import { useEffect } from 'react';

import Holiday1 from "../../img/Posts/Post6_1.jpg"
import Holiday2 from "../../img/Posts/Post6_2.jpg"
import Holiday3 from "../../img/Posts/Post6_3.jpg"
import Holiday4 from "../../img/Posts/Post6_4.jpg"

import Valborg1 from "../../img/Posts/Post6_5.jpg"
import Valborg2 from "../../img/Posts/Post6_6.jpg"
import Valborg3 from "../../img/Posts/Post6_7.jpg"

import Gala1 from "../../img/Posts/Post6_8.jpg"
import Gala2 from "../../img/Posts/Post6_9.jpg"
import Gala3 from "../../img/Posts/Post6_10.jpg"

import Visit1 from "../../img/Posts/Post6_11.jpg"
import Visit2 from "../../img/Posts/Post6_12.jpg"
import Visit3 from "../../img/Posts/Post6_13.jpg"
import Visit4 from "../../img/Posts/Post6_14.jpg"


const customArrows = {
  prevArrow: <div style={{width: "30px", marginRight: "-30px"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="hsl(205, 78%, 60%)"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/></svg></div>,
  nextArrow: <div style={{width: "30px", marginLeft: "-30px"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="hsl(205, 78%, 60%)"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/></svg></div>
}
const Title = "Ik leef nog!";

function Blog6() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id='Blog_31-5-22' className="Blog Page">
      <Helmet>
        <title>{Title}</title>
      </Helmet>
      <h1>{Title}</h1>
      <h3 className='Date'>31 mei 2022</h3>
      <hr/>
      <p>
        Daar ben ik dan eindelijk weer! Het is inmiddels meer dan twee maanden geleden dat ik voor het laatst iets van me heb laten horen. Maar het gezegde is 
        waar: Geen bericht, is goed bericht! Het gaat heel goed met me en ik ben allemaal leuke dingen aan het doen. Jullie hebben nog een deel twee van Lapland 
        van me tegoed, maar ik wil jullie graag eerst wat meer op de hoogte brengen van mijn leven hier. 
      </p>
      <p>
        Het aftellen is langzaamaan begonnen. Het is nog maar een maand voordat ik weer naar Nederland ga. Dat geeft een dubbel gevoel, aan de ene kant wil ik 
        nog lang niet naar huis. Ik wil (nog) niet achter laten wat ik deze vier maanden hier heb gecreëerd. Maar aan de andere kant neemt mijn heimwee steeds 
        een beetje toe en begin ik ook wel meer zin te krijgen om weer naar huis te gaan. Ik ben ook wel blij dat dit gevoel gekomen is, want dat maakt het iets 
        makkelijker om eind juni weer terug te komen. 
      </p>
      <p>
        Maar ik heb nog een maand hier, waarin ik heel veel leuke dingen ga doen! Deze week sluit ik mijn laatste vak <i>Gender and Economic Development</i> af. 
        Ik moet de eindopdracht hiervan voor deze vrijdag inleveren. Als ik dat heb ingeleverd, ben ik klaar met mijn lessen in Uppsala en daarmee ook met mijn 
        hele bachelor! Dan kan ik mijn diploma aanvragen en afstuderen! 
      </p>
      <p>
        Al ben ik de afgelopen weken eigenlijk niet heel veel bezig geweest met studeren. Ik ben vooral leuke tripjes aan het doen. Op het moment van schrijven 
        ben ik nog aan het bijkomen van mijn trip naar Tallinn (Estland) afgelopen weekend en is mijn kamer nog een bende door mijn roadtrip naar Lund, het 
        weekend daarvoor. En alsof dat nog niet genoeg was, vertrek ik vrijdag naar Gotland voor zes dagen! Dat is een eiland in de Oostzee voor de Zweedse Kust. 
        Als ik tijd heb, vertel ik jullie daar meer over in speciale blogs toegewijd aan die tripjes. 
      </p>

      <div className="slide-container">
        <Slide {...customArrows} duration={10000} indicators={true}>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Holiday1} alt="Universiteit Lund" />
            <p className="subtitle">Universiteitsbibliotheek van Lund</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Holiday2} alt="Concert tijdens het carnaval van Lund, de reden waarom we naar Lund gingen" />
            <p className="subtitle">Concert tijdens het carnaval van Lund, de reden waarom we naar Lund gingen</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Holiday3} alt="Wandelen in de Zweedse natuur met Kerttu tijdens onze roadtrip" />
            <p className="subtitle">Wandelen in de Zweedse natuur met Kerttu tijdens onze roadtrip</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Holiday4} alt="Stadsmuur en kathedraal van Tallinn" />
            <p className="subtitle">Stadsmuur en kathedraal van Tallinn</p>
          </div>
        </Slide>
      </div>

      <p>
        Het enige nadeel van die tripjes is dat ik dan wel minder tijd in Uppsala kan spenderen. Dat vind ik jammer, want de tijd is eindig en ik heb het heel 
        gezellig hier. Thuis voelt het inmiddels heel huiselijk aan. Als ik terug kom van een tripje wordt er gevraagd of ik alweer thuis ben en of ik naar de 
        keuken kom. Daarnaast zijn mijn huisgenoten, maar ook andere vrienden die ik hier gemaakt heb, oprecht geïnteresseerd naar hoe het met mij gaat en dat 
        is erg fijn om te ervaren. De gedachte dat ik dit over een maand achter moet laten, is dan ook erg moeilijk en wil ik elk moment dat ik hier ben, 
        benutten om hier van te genieten. 
      </p>
      <p>
        Uppsala wordt ook steeds mooier en mooier. De lente (en bijna zelfs al zomer) is hier na een lange tijd dan toch echt aangebroken. De bomen staan in 
        bloei, bloemetjes komen op en de stad wordt langzaamaan zomer klaar gemaakt. Gele bankjes en bloembakken worden neergezet langs de weg en terrassen 
        verschijnen op plekken waarvan ik eerst niet eens wist dat daar een restaurant zat.
      </p>
      <p>
        Na de lange wintermaanden was de lente ook meer dan welkom en haar komst werd in Uppsala dan ook goed gevierd. Dat gebeurde met een special feestdag: 
        Valborg. Dit wordt gevierd op 30 april en de dagen voorafgaand hieraan. Hopelijk vind ik de tijd om hier in een ander blog nog dieper op in te gaan, 
        want Valborg was een bijzonder feest met veel tradities! 
      </p>

      <div className="slide-container">
        <Slide {...customArrows} duration={10000} indicators={true}>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Valborg1} alt="De Valborg bootrace" />
            <p className="subtitle">De Valborg bootrace</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Valborg2} alt="IJscocktail in de tuin van GH met Elize, Alice en Kerttu (vriendinnen/huisgenoten)" />
            <p className="subtitle">IJscocktail in de tuin van GH met Elize, Alice en Kerttu (vriendinnen/huisgenoten)</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Valborg3} alt="Valborg bij het stadshuis" />
            <p className="subtitle">Valborg bij het stadshuis</p>
          </div>
        </Slide>
      </div>
      
      <p>
        Daarnaast ben ik ook nog bij het lentebal geweest! Dit was op 14 mei en alle nations hadden dit bal op hetzelfde moment! Het bal was net iets 
        uitgebreider dan een <Link className='Link' to="/blogs/blog_6-3-22">gasque</Link>. Je moest bijvoorbeeld een lange jurk aan, iets wat bij andere 
        gasques niet verplicht is. Ook kregen we een extra dessert, extra dessertwijn en waren er speciale gasten aanwezig bij het bal. Het feest na het 
        bal duurde tot vier uur en toen was het inmiddels alweer licht buiten.
      </p>
      <p>
        Met onze medestudenten en balgangers zijn Kerttu (Finse huisgenoot/vriendin) en ik toen de heuvel opgelopen naar het kasteel, om vanaf hier de zonsopgang 
        te bekijken. Dit was een hele bijzondere ervaring. Veel mensen hadden zich op de heuvel verzameld en verschillende groepjes waren aan het zingen, terwijl 
        we de zon op zagen komen. Dit vergeet ik nooit meer.
      </p>

      <div className="slide-container">
        <Slide {...customArrows} autoplay={false} indicators={true}>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Gala1} alt="Kerttu, ik en Larissa in de tuin van GH voor het lentebal" />
            <p className="subtitle">Kerttu, ik en Larissa in de tuin van GH voor het lentebal</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Gala2} alt="Voor het diner van het lentebal!" />
            <p className="subtitle">Voor het diner van het lentebal!</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Gala3} alt="Zonsopgang gezien vanaf het kasteel" />
            <p className="subtitle">Zonsopgang gezien vanaf het kasteel</p>
          </div>
        </Slide>
      </div>

      <p>
        In de twee maanden dat ik niets heb geschreven heb ik ook wat visite gehad. Eerst is <span data-tip="Mijn lieve, knappe, grappige, fantastische vriend en 
        tevens de programmeur van deze website"className='Tooltip_Word'>Daniel</span> langsgekomen. Dat was fijn en erg gezellig. Wij hebben samen een tripje 
        gemaakt naar Norrköping een leuke, oude industriestad op drie uur reizen vanaf Uppsala. Het wordt ook wel het Manchester van Zweden genoemd. Kort 
        na Daniel zijn bezoek, kwamen <span data-tip="Vriendinnen van thuis" className='Tooltip_Word'>Amber en Sara</span> langs en dat was ook heel erg gezellig. 
        Ik heb ze de stad laten zien en ben samen met hen voor het eerst écht goed naar Stockholm gegaan. 
      </p>
      <p>
        Het laatste bezoek was van mijn ouders en <span data-tip="Mijn zusje" className='Tooltip_Word'>Isa</span>. Zij zijn met de auto naar mij toegereden en sliepen 
        in een appartement in de stad. Ik ben blij dat ik ze heb kunnen laten zien waar ik nu woon. Ik was bovendien ook blij dat zij met de auto waren gekomen, toen 
        heb ik voor het eerst Uppsala en omgeving vanuit de auto ervaren! Isa is nog een nachtje extra gebleven, zodat ze het studentenleven in Uppsala ook kon ervaren. 
      </p>

      <div className="slide-container">
        <Slide {...customArrows} duration={10000} indicators={true}>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Visit1} alt="Norrköping en haar typerende tram" />
            <p className="subtitle">Norrköping en haar typerende tram</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Visit2} alt="Toen Daniel er was in begin April sneeuwde het" />
            <p className="subtitle">Toen Daniel er was in begin April sneeuwde het</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Visit3} alt="Met Amber en Sara in een heel goed café in Stockholm!" />
            <p className="subtitle">Met Amber en Sara in een heel goed café in Stockholm!</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={Visit4} alt="Op pad met de familie in de Zweedse natuur" />
            <p className="subtitle">Op pad met de familie in de Zweedse natuur</p>
          </div>
        </Slide>
      </div>

      <p>
        Hoewel ik goede vriendschappen hier heb opgebouwd en het hier erg naar mijn zin heb, was het fijn om bezoek te krijgen. Dat voelt toch het meest vertrouwd. 
        Ik vond het ook erg leuk om aan iedereen te laten zien hoe mijn leven er hier uitziet. 
      </p>
      <p>
        Het voelde goed om weer eens wat van me te laten horen! Ik ben mijn blog zeker niet vergeten, maar soms (lees: vaak) schiet het schrijven van blogs er wat 
        bij in. Dat betekent alleen maar dat ik het heel leuk heb hier en herinneringen aan het maken ben, om later blogs over te schrijven! Ik laat snel weer wat 
        van mij horen!
      </p>
      
      <p>Hej då, <br />Babette </p>
      <div className="prevNextButs">
        <Link id='nextBlog' className='Link' to="/blogs/Blog_28-9-22"> Volgende Blog: <br /><span>6x Zweden missen - Editie: Dagelijks leven</span> </Link>
        <Link id='prevBlog' className='Link' to="/blogs/blog_19-3-22"> Vorige Blog: <br /><span>Li-La-Lapland (Deel 1)</span></Link>  
      </div>

      <ReactTooltip offset={{top: -10}} effect='solid'/>
    </div>
  );
}

export default Blog6;