import '../Page.css';
import './Blog.css';

import React from 'react';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import ReactTooltip from "react-tooltip";
import { useEffect } from 'react';

import nation1 from "../../img/Posts/Post3_1.jpg"
import nation2 from "../../img/Posts/Post3_3.jpg"
import nation3 from "../../img/Posts/Post3_4.jpg"
import nation4 from "../../img/Posts/Post3_5.jpg"
import nation5 from "../../img/Posts/Post3_6.jpg"
import nation6 from "../../img/Posts/Post3_7.jpg"
import nation7 from "../../img/Posts/Post3_8.jpg"

import building1 from "../../img/Posts/Post3_9.jpg"
import building2 from "../../img/Posts/Post3_10.jpg"
import building3 from "../../img/Posts/Post3_11.jpg"
import building4 from "../../img/Posts/Post3_12.jpg"

import map from "../../img/Posts/Post3_13.jpg"

const customArrows = {
  prevArrow: <div style={{width: "30px", marginRight: "-30px"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="hsl(205, 78%, 60%)"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/></svg></div>,
  nextArrow: <div style={{width: "30px", marginLeft: "-30px"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="hsl(205, 78%, 60%)"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/></svg></div>
}
const Title = "Een nation hier en een nation daar";

function Blog3() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id='Blog_1-2-22' className="Blog Page">
      <Helmet>
        <title>{Title}</title>
      </Helmet>
      <h1>{Title}</h1>
      <h3 className='Date'>1 februari 2022</h3>
      <hr/>
      <p>
        Daar ben ik dan eindelijk weer! Ik zit hier nu inmiddels meer dan twee weken en de tijd is voorbij gevlogen! Ik heb echt tijd vrij moeten maken om deze blog te gaan schrijven, 
        want mijn dagen zitten goed vol gepland. Met bijvoorbeeld <span data-tip="Dat is vrij vertaald koffie met gebak" className='Tooltip_Word'>fika</span> doen, studeren en mijzelf 
        in leven houden. Maar daarover in een ander blog meer. Om jullie een zo goed mogelijk beeld te geven over mijn leven hier, moet ik jullie eerst over de studenten nations vertellen.
      </p>
      <ReactTooltip offset={{top: -10}} effect='solid'/>
      <p>
        Mijn voornaamste bezigheid deze weken was namelijk het bezoeken van de nations. De nations zijn de studentenverenigingen in Uppsala. Ze heten nations, omdat ze allemaal genoemd 
        zijn naar deelgebieden van Zweden. Zo heb je bijvoorbeeld <i>Stockholm nation</i>, <i>Göteborg nation</i> en <i>Kalmar nation</i>. Ze hebben deze namen omdat studenten afkomstig 
        uit deze gebieden ooit de nation hebben opgericht. Op deze manier hadden studenten uit hetzelfde gebied een vertrouwde plek waar ze samen konden komen en hun gewoontes en 
        tradities voort konden zetten. Ze hadden zo een thuis in Uppsala.
      </p>

      <h3>Pubs</h3>
      <p>
        <i>Västgöta</i>, de oudste nation, is opgericht in 1639. Dat is ongeveer 150 jaar na de oprichting van de Universiteit van Uppsala in 1477. Inmiddels telt Uppsala dertien 
        officiële studenten nations. Omdat de nations al zo lang een onderdeel zijn van het leven in Uppsala, hebben zij mooie gebouwen in het centrum van Uppsala. De meeste nations 
        vereisen voor een lidmaatschap tegenwoordig niet meer dat je een oorspronkelijke inwoner bent van het gelijknamige gebied in Zweden. Dat is dus goed nieuws voor de 
        internationale studenten. Wij mogen overal lid worden, al staan sommige nations wel meer open voor internationale studenten dan andere.       
      </p>

      <div className="slide-container">
        <Slide {...customArrows} duration={10000} indicators={true}>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={building1} alt='Snerikes nation'/>
            <p className="subtitle">Södermanlands-Nerikes nation (Snerikes)</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={building2} alt='Snerikes van binnen'/>
            <p className="subtitle">Södermanlands-Nerikes (Snerikes) van binnen</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={building3} alt='Plafond van GH'/>
            <p className="subtitle">Plafond van Gästrike-Hälsinge (GH)</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={building4} alt='Västgöta nation'/>
            <p className="subtitle">Västgöta nation</p>
          </div>
        </Slide>
      </div>

      <p>
        De studenten nation in Uppsala is niet helemaal te vergelijken met de studentenvereniging in Nederland. Elke nation heeft bijvoorbeeld een eigen pub en deze is bijna de hele 
        week open. Deze pubs hebben nu door de coronamaatregelen iets minder capaciteit dan normaal. Dat betekent dat je nu vroeg op de avond binnen moet zijn, wil je zeker zijn van 
        een plek. Dat de pubs zo geliefd zijn, heeft naast de gezelligheid ook te maken met de prijzen die de nations hanteren.
      </p>
      <p>
        Zweden voert namelijk een hoge accijns op alcohol wat betekent dat alcohol in de winkels en restaurants heel erg duur is. Nations hoeven deze accijns niet of veel minder 
        te betalen en daarom is een biertje bij de nations wél te betalen. Een biertje bij de nations kost tussen de 3-4 euro, terwijl je in een restaurant makkelijk meer dan 6 
        euro betaalt. Studenten zitten dus veel liever bij de nations dan bij de bars en restaurants in de stad. Daarnaast kun je in de pubs ook eten voor een schappelijke prijs.
      </p>

      <h3>Soeplunch</h3>
      <p>
        Wat ik persoonlijk erg leuk vind aan de studenten nations in Uppsala, en wat echt anders is dan in Nederland, is dat je naar bijna alle activiteiten kunt gaan ook al ben 
        je geen lid van die specifieke nation. Het belangrijkste is dat je érgens lid bent. Je krijgt dan een nationkaart en met die kaart heb je toegang tot de pubs, clubs en 
        andere activiteiten. Het kan wel zo zijn dat je meer moet betalen als je geen lid bent. Het is daarom nog wel belangrijk om een nation uit te kiezen die goed bij je past, 
        zodat je niet extra betaalt voor de activiteiten die jij leuk vindt!
      </p>
      <p>
        Elke nation heeft namelijk een ander karakter of staat ergens anders om bekend. Zo heeft <i>Kalmar</i> open microfoon avonden (Je kunt je dan dus inschrijven om iets op 
        te voeren op die avond). <i>Stockholm</i>, <i>Södermanlands-Nerikes (Snerikes)</i>, <i>Värmlands</i> en <i>Norrlands</i> hebben een club met een specifieke clubavond, 
        al zijn die nu nog niet open helaas. <i>Västgota</i> heeft elke dag een soeplunch voor een goede prijs. <i>Gästrike-Hälsinge (GH)</i> staat bekend om de 2 voor 1 burgerdeal 
        op dinsdag en de pannenkoekenbrunch op zaterdag. En zo kan ik nog wel even doorgaan. 
      </p>
      <p>
        Daarnaast hebben sommige nations ook nog sportteams, koren, orkesten of theatergroepen. Je hoeft je dus geen moment te vervelen in Uppsala. Mocht je dat wel doen, kun je 
        ook altijd nog gaan werken bij een van de nations. Studenten met een Zweeds persoonsnummer krijgen hier een kleine vergoeding voor, maar internationale studenten krijgen 
        betaald met gratis eten. 
      </p>

      <div className="imgDiv">
        <img src={map} alt="Alle nations op de kaart" />
        <p className="subtitle">Alle nations op de kaart</p>
      </div>

      <h3>Zweeds diner</h3>
      <p>
        Je sluit bij een nation een lidmaatschap af voor één semester, wat betekent dat de internationale studenten die komen voor één exchange semester ook makkelijk lid kunnen 
        worden. Je mag ook bij meerdere nations lid worden, je moet dan alleen wel meer betalen. De vraag is dan natuurlijk: Waar word ik lid? Om deze keuze ‘makkelijker’ te maken 
        werden door de nations veel activiteiten georganiseerd om ons, de internationale studenten, kennis te laten maken met de nations. Wij kregen een tijdelijke nationkaart 
        van twee weken om deze activiteiten te kunnen bezoeken.
      </p>
      <p>
        En ik heb aan die activiteiten meegedaan! Ik heb een pannenkoekenbrunch en bierproeverij gehad bij <i>GH</i>, een mooie wandeling gemaakt en spelletjes gespeeld bij <i>Uplands</i>,
        fika gehad bij <i>Smålands</i>, ontbijt gegeten bij <i>Stockholm</i>, nog meer spelletjes gespeeld bij <i>Östgöta</i> en een Zweeds diner gehad bij <i>Västgöta</i>. 
        Daarnaast ben ik ook nog in de pub geweest bij <i>Snerikes</i> en <i>Värmlands</i>. Ik heb dus al aardig wat nations gezien, maar ook nog een groot aantal niet. 
        Bij vijf nations heb ik nog geen activiteit gehad, maar wie weet ga ik die zien op de kroegentocht waar ik aanstaande vrijdag aan mee ga doen!
      </p>

      <div className="slide-container">
        <Slide {...customArrows} duration={10000} indicators={true}>
          <div className="VerticalCenter">
            <div className='each-slide imgDiv SlideDiv'>
              <img src={nation1} alt='Pannenkoekenbrunch bij Gästrike-Hälsinge (GH)'/>
              <p className="subtitle">Pannenkoekenbrunch bij Gästrike-Hälsinge (GH)</p>
            </div>
          </div>
          <div className="VerticalCenter">
          <div className='each-slide imgDiv SlideDiv'>
            <img src={nation2} alt='Diner bij snerikes'/>
            <p className="subtitle">Diner bij Södermanlands-Nerikes (Snerikes)</p>
          </div>
          </div>
          <div className="VerticalCenter">
          <div className='each-slide imgDiv SlideDiv'>
            <img src={nation3} alt='Soeplunch bij Västgöta'/>
            <p className="subtitle">Soeplunch bij Västgöta</p>
          </div>
          </div>
          <div className="VerticalCenter">
          <div className='each-slide imgDiv SlideDiv'>
            <img src={nation4} alt='Ontbijt bij Stockholm'/>
            <p className="subtitle">Ontbijt bij Stockholm</p>
          </div>
          </div>
          <div className="VerticalCenter">
          <div className='each-slide imgDiv SlideDiv'>
            <img src={nation5} alt='Zweeds diner bij Västgöta'/>
            <p className="subtitle">Zweeds diner bij Västgöta</p>
          </div>
          </div>
          <div className="VerticalCenter">
          <div className='each-slide imgDiv SlideDiv'>
            <img src={nation6} alt='Wandeling over de Viking Heuvels bij Gamla Uppsala'/>
            <p className="subtitle">Wandeling over de Viking Heuvels bij Gamla Uppsala met Uplands nation</p>
          </div>
          </div>
          <div className="VerticalCenter">
          <div className='each-slide imgDiv SlideDiv'>
            <img src={nation7} alt='Ik blij na de lunch'/>
            <p className="subtitle">Ik blij na de lunch</p>
          </div>
          </div>
        </Slide>
      </div>

      <p>
        Ik heb mij inmiddels aangemeld bij <a href='https://ghnation.se/en/' target="_blank" rel="noreferrer">GH</a>. Zij staan bekend om hun burgers, hebben 190 verschillende 
        bieren én hebben een sportvereniging. Ik werd van alles enthousiast en heb toen gewoon maar besloten om hier lid van te worden. Ik vond het fijn dat de druk van het 
        ‘moeten kiezen’ er toen af was. Mocht ik het ergens anders toch ook heel leuk vinden, kom ik daar met mijn nationkaart altijd nog binnen of meld ik mij daar ook aan.
      </p>
      <p>
        Met al die activiteiten zit mijn agenda dus snel vol. Inmiddels is mijn tijdelijke kaart verlopen en ben ik benieuwd of ik het nu iets rustiger krijg. Maar 
        waarschijnlijk niet. Wanneer ik namelijk niet bij een nation ben, dan speel ik wel spelletjes met mijn huisgenoten of ik herinner me dat ik ook nog artikelen moet 
        lezen voor mijn lessen. Verder begint volgende week mijn cursus Zweeds. Ik heb het hier dus erg naar mijn zin en verveel mij absoluut niet. Binnenkort zal ik meer 
        over mijn dagelijks leven en de stad vertellen!
      </p>
      <p>
        Ik hou jullie op de hoogte!
      </p>
      
      <p>Hej då, <br />Babette </p>

      <div className="prevNextButs">
        <Link id='nextBlog' className='Link' to="/blogs/blog_6-3-22"> Volgende Blog: <br /><span>Eat, sing, drink, repeat</span></Link>
        <Link id='prevBlog' className='Link' to="/blogs/blog_20-1-22"> Vorige Blog: <br /><span>Een groen begin</span></Link>  
      </div>
    </div>
  );
}

export default Blog3;