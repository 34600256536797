import '../Page.css';
import './Blog.css';

import React from 'react';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useEffect } from 'react';

import vliegen1 from "../../img/Posts/Post2_2.jpg"
import vliegen2 from "../../img/Posts/Post2_1.jpg"
import vliegen3 from "../../img/Posts/Post2_3.jpg"
import vliegen4 from "../../img/Posts/Post2_6.jpg"
import vliegen5 from "../../img/Posts/Post2_8.jpg"

import huis1 from "../../img/Posts/Post2_4.jpg"
import huis2 from "../../img/Posts/Post2_5.jpg"

import aurora from "../../img/Posts/Post2_7.jpg"

const customArrows = {
  prevArrow: <div style={{width: "30px", marginRight: "-30px"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="hsl(205, 78%, 60%)"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/></svg></div>,
  nextArrow: <div style={{width: "30px", marginLeft: "-30px"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="hsl(205, 78%, 60%)"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/></svg></div>
}

const Title = "Een groen begin";

function Blog2() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id='Blog_20-1-22' className="Blog Page">
      <Helmet>
        <title>{Title}</title>
      </Helmet>
      <h1>{Title}</h1>
      <h3 className='Date'>20 januari 2022</h3>
      <hr/>
      <p>
        Na een emotioneel afscheid op Schiphol, veel wind bij de landing en een lange zoektocht naar de welkomstdesk op Arlanda Airport, ben ik dan (eindelijk) in Uppsala! 
        Inmiddels zit ik al op mijn zesde dag hier en ik heb het naar mijn zin! Het zijn drukke dagen geweest. Gisteren was de eerste dag dat ik niet meer dan 10000 stappen 
        heb gelopen. Ik heb al zoveel meegemaakt! Maar in deze blog zal ik jullie eerst vertellen over de dag van vertrek en aankomst, 14 januari.
      </p>
      <p>
        Hoewel ik echt enthousiast was om weg te gaan, was dat enthousiasme even helemaal weg op de dag van vertrek. Het leek wel alsof ik al mijn emoties had uitgeschakeld, 
        om maar niet te hoeven denken aan het afscheid. Want dat afscheid was moeilijk.
      </p>
      
      <h3>Sneeuw</h3>
      <p>
        Door het coronavirus mag je als niet-reiziger niet meer bij de vertrekhallen bij Schiphol komen. Ik moest daarom gedag zeggen voor de trappen naar de vertrekhallen toe. 
        Het afscheid was bijzonder. Allereerst, omdat meer vriendinnen waren gekomen om afscheid te nemen dan ik van tevoren had verwacht en zij (heel erg lief) een kussensloop 
        voor mij hadden laten bedrukken met foto’s. Ten tweede omdat het erg raar is om voor zo’n lange tijd afscheid te nemen. En als laatste omdat, ondanks dat het verdrietig 
        was, het ook bijzonder is om te zien hoeveel mensen om mij geven en het jammer vinden dat ik wegga. 
      </p>
      <p>
        Op Schiphol liep ik na het inchecken een beetje verdoofd rond. Ik was aan de ene kant nog heel erg dicht bij huis, maar het voelde ver weg. Door mijn hoofd ging de 
        gedachte: <i>“Ik kan nog aan mijn moeder vragen of ze mij op komt halen”</i>. Maar dat heb ik niet gedaan en ik ben, na even gewacht te hebben, in het vliegtuig 
        gestapt! Pas toen ik boven Zweden vloog en ik overal kleine stukjes sneeuw en ijs zag, dacht ik: <i>“Oké dit is vet, ik heb er zin in!”</i>. 
      </p>
      <p>
        Op het vliegveld in Zweden kreeg ik bij de welkomstdesk van de Universiteit van Uppsala de sleutels van mijn kamer. Daarna werd ik met een shuttlebus, verzorgd door de 
        universiteit, naar mijn accommodatie gebracht. In die bus zaten vooral Nederlanders (omdat wij allemaal van dezelfde vlucht kwamen) dus dat was meteen vertrouwd en gezellig. 
      </p>

      <div className="slide-container">
        <Slide {...customArrows} duration={10000} indicators={true}>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={vliegen1} alt='Het afscheidscomité'/>
            <p className="subtitle">Het afscheidscomité</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={vliegen2} alt='Echt afscheid nemen'/>
            <p className="subtitle">Echt afscheid nemen</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={vliegen3} alt='Daar ging ik dan!'/>
            <p className="subtitle">Daar ging ik dan!</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={vliegen4} alt='Hier vloog ik boven Zweden! Op deze foto helaas wel zonder sneeuw.'/>
            <p className="subtitle">Hier vloog ik boven Zweden! Op deze foto helaas wel zonder sneeuw</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={vliegen5} alt='In de bus naar Uppsala'/>
            <p className="subtitle">In de bus naar Uppsala</p>
          </div>
        </Slide>
      </div>

      <h3>Internationaal</h3>
      <p>
        Om kwart over drie kwamen wij bij mijn huis aan. Ik woon in Flogsta, dé studentenwijk van Uppsala. Flogsta ligt aan de westelijke rand van de stad en heeft veel 
        studentenflats. Met ongeveer 40 minuten lopen ben je in het centrum. Dat is wel wat ver. Maar gelukkig ben je met de fiets of bus binnen 10 minuten in het centrum, 
        zit je dicht bij de natuur en bij vele andere studenten! 
      </p>
      <p>
        Mijn kamer is op de bovenste verdieping, de 7e, en het uitzicht is echt prachtig. Ik heb een eigen badkamer, maar de keuken deel ik met elf anderen. Mijn huisgenoten 
        zijn allemaal internationale studenten met verschillende nationaliteiten. Ze komen uit Zuid-Afrika, India, China, Bhutan, Rusland, Italië, Finland, België en Nederland. 
        Ik vind het echt heel leuk om op zo’n internationale gang te zitten. Ik heb in vijf dagen al veel geleerd over andere culturen en landen. Maar ik vind het ook fijn 
        dat ik af en toe met mijn Nederlandse en twee Vlaamse huisgenoten Nederlands kan spreken. 
      </p>
      <p>
        Snel nadat ik aankwam, ben ik met Nienke en Nadine, mijn Nederlandse huisgenoot en een andere Nederlandse, naar de Ikea geweest (typisch). Ondanks dat mijn kamer al 
        best goed gemeubileerd was, met bijvoorbeeld een bed, kasten, bureau, stoel en plankjes, miste ik toch wat handige dingen in mijn kamer. Zo heb ik, onder andere, 
        douchematten, een prullenbak, een warm kleedje en kerstlichtjes gekocht. Dat maakt toch dat de kamer iets meer als mijn kamer voelt. Bovendien staat mijn kamer vol 
        op de wind, dus is het ook behoorlijk koud. Een extra deken was daarom geen overbodige luxe. 
      </p>
      <div className='imgDiv'>
        <img src={huis1} alt='Mijn kamer toen ik net was aangekomen, als je goed kijkt zie je het mooie uitzicht'/>
        <p className="subtitle">Mijn kamer toen ik net was aangekomen, als je goed kijkt zie je het mooie uitzicht</p>
        <hr className='small' />
        <img src={huis2} alt='Mijn eigen badkamer'/>
        <p className="subtitle">Mijn eigen badkamer</p>
      </div>

      <h3>Noorderlicht</h3>
      <p>
        Na het shoppen en eten bij de Ikea, zijn wij thuis meteen doorgegaan naar de supermarkt. Die zit op 3 minuten lopen van mijn huis en is open tot half 12. Na de 
        essentiële dingen gekocht te hebben, had ik het wel even moeilijk toen ik weer alleen op mijn kamer zat. Belletjes naar het thuisfront maakten me wel weer rustiger, 
        maar het echte medicijn moest toen nog komen.
      </p>
      <p>
        Toen ik namelijk met nog wat huisgenoten thee zat te drinken, kreeg één van hen een berichtje dat het noorderlicht te zien zou zijn. Wij renden naar ons balkon 
        en daar zagen we inderdaad een groene gloed in de lucht! Dit was echt heel erg bijzonder om te zien en zeker op de eerste dag. Huisgenoten die hier al zes maanden 
        zitten, hadden dit bijvoorbeeld nog nooit gezien. Dat was een warm welkom!
      </p>

      <div className='imgDiv'>
        <img id='aurora' src={aurora} alt="Het noorderlicht" />
        <p className="subtitle">Het noorderlicht! Op de foto wel groener dan ik hem zelf kon zien</p>
      </div>

      <p>
        Op deze dag is zoveel gebeurd dat ik eigenlijk niet anders kon dan een heel blog hieraan te wijden. In de volgende blog zal ik meer vertellen over rest van de dagen. 
        Zo heb ik bijvoorbeeld al een fiets gekocht, ben ik naar Nations (studentenverenigingen) geweest en is Uppsala echt prachtig. Maar daarover later meer, ik hou jullie 
        op de hoogte! 
      </p>
      
      <p>Hej då, <br />Babette </p>

      <div className="prevNextButs">
        <Link id='nextBlog' className='Link' to="/blogs/blog_1-2-22"> Volgende Blog: <br /><span>Een nation hier en een nation daar</span></Link>
        <Link id='prevBlog' className='Link' to="/blogs/blog_9-1-22"> Vorige Blog: <br /><span>1 week vóór vertrek: Bestellen, stressen en<br/>Zweeds leren</span></Link>  
      </div>
    </div>
  );
}

export default Blog2;