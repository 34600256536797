import '../Page.css';
import './Blog.css';

import React from 'react';
import 'react-slideshow-image/dist/styles.css'
import { Slide } from 'react-slideshow-image';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import { useEffect } from 'react';

import gasque from "../../img/Posts/Post4_1.jpg"
import gasque1 from "../../img/Posts/Post4_2.jpg"
import gasque2 from "../../img/Posts/Post4_3.jpg"
import gasque3 from "../../img/Posts/Post4_4.jpg"
import gasque4 from "../../img/Posts/Post4_5.jpg"
import gasque5 from "../../img/Posts/Post4_6.jpg"
import gasque6 from "../../img/Posts/Post4_7.jpg"
import gasque7 from "../../img/Posts/Post4_8.jpg"
import gasque8 from "../../img/Posts/Post4_9.jpg"
import gasque9 from "../../img/Posts/Post4_10.jpg"
import gasque10 from "../../img/Posts/Post4_11.jpg"
import gasque11 from "../../img/Posts/Post4_12.jpg"
import gasque12 from "../../img/Posts/Post4_13.jpg"

const customArrows = {
  prevArrow: <div style={{width: "30px", marginRight: "-30px"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="hsl(205, 78%, 60%)"><path d="M242 180.6v-138L0 256l242 213.4V331.2h270V180.6z"/></svg></div>,
  nextArrow: <div style={{width: "30px", marginLeft: "-30px"}}><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="hsl(205, 78%, 60%)"><path d="M512 256L270 42.6v138.2H0v150.6h270v138z"/></svg></div>
}
const Title = "Eat, sing, drink, repeat";

function Blog4() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id='Blog_6-3-22' className="Blog Page">
      <Helmet>
        <title>{Title}</title>
      </Helmet>
      <h1>{Title}</h1>
      <h3 className='Date'>6 maart 2022</h3>
      <hr/>
      <p>
        Het is zaterdagmiddag. Ik zit in café Linné in het centrum van Uppsala en ik ben herstellende van de gasque van gisteravond. Ik ben samen met Tabea (Duits) en 
        Kajsa (Deens). Ik heb net een luxe tosti op, die voor Nederlandse begrippen aan de dure kant is (€9,50), maar hij was heel erg lekker. Ik heb nu eindelijk tijd 
        om even een blog te schrijven en jullie een klein beetje te updaten over mijn leven hier!
      </p>
      
      <h3>Gasque</h3>
      <p>
        Gisteren was ik dus op een gasque. Een gasque is een chic diner georganiseerd door de nations. Het is echt een studententraditie. Tijdens een driegangendiner 
        zing je (voornamelijk Zweedse) liedjes uit je <span data-tip="Zweeds voor liedboek" className='Tooltip_Word'>sångbok</span>.  Na elk liedje proost je, zonder 
        de glazen te klinken, en neem je één slok (anders dan bij een cantus in Nederland hoef je dus niet je hele glas op te drinken). 
      </p>
      <p>
        Je eerste slokken zijn van de <span data-tip="Sterke drank" className='Tooltip_Word'>schnaps</span>, daarna  drink je bier of cider, bij het hoofdgerecht wordt 
        wijn geserveerd en bij het dessert heb je weer keuze uit verschillende <i>schnaps</i>. Dit alles is natuurlijk als je alcohol wilt drinken. Je kunt ook voor de 
        alcoholvrije optie kiezen, dan betaal je ook minder. De kosten voor de meeste gasques liggen rond de dertig euro. 
      </p>
      <p> 
        Elke nation heeft zijn eigen tradities tijdens de gasque. Zo zijn bijvoorbeeld niet alle liedjes die gezongen worden hetzelfde, elke nation heeft net iets andere 
        liederen in het <i>sångbok</i> staan. Ik was gisteren op de gasque van <i>Västgöta nation</i> (VG), maar ik ben lid bij <i>Gästrike-Hälsinge nation</i> (GH) , en 
        heb dan ook het liedboek van GH gekocht. De volgorde van de liedjes zijn niet hetzelfde in beide boeken en helaas stonden ook veel liedjes die we zongen 
        überhaupt niet in mijn boek. Gelukkig mocht ik het liedboek van VG van mijn huisgenoot Elize lenen en kon ik toch mee blijven zingen!
      </p>

      <h3>Släpp</h3>
      <p>
        Een traditie die wel overal hetzelfde is, is het schrijven in het liedboek aan het einde van het diner. Iedereen neemt naar de gasque een pen mee, en 
        schijft/tekent/knutselt dan iets leuks in het boek van zijn of haar tafelgenoten. Dit mag je alleen nog niet dezelfde avond lezen! Dat brengt ongeluk en zorgt 
        ervoor dat je je studie niet haalt! De volgende ochtend als je dus met een kater in bed ligt, kan je de pijn verzachten door de leuke dingen in je boekje te lezen. 
      </p>

      <div className="slide-container">
        <Slide {...customArrows} duration={10000} indicators={true}>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={gasque} alt="De 'luxe tosti' bij Café Linné"/>
            <p className="subtitle">De 'luxe tosti' bij Café Linné</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={gasque1} alt='De Songbook Gasque bij Västgöta nation'/>
            <p className="subtitle">De Songbook Gasque bij Västgöta nation</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={gasque2} alt='Dit is mijn zangboek!'/>
            <p className="subtitle">Dit is mijn zangboek!</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={gasque3} alt="Een 'beschreven' pagina van mijn zangboek"/>
            <p className="subtitle">Een 'beschreven' pagina van mijn zangboek</p>
          </div>
        </Slide>
      </div>
      
      <p>
        Een andere gedeelde traditie is de afterparty, de <i>släpp</i>, na elke gasque. Een gasque eindigt ongeveer rond 23:00 en daarna is het tijd om te dansen! Bij dit 
        feest zijn ook andere studenten welkom die niet bij de gasque aanwezig waren. Dit feest duurt dan meestal tot 4:00 en is, van wat ik tot nu toe heb meegemaakt, 
        héél gezellig. 
      </p>

      <h3>Reccegasque</h3>
      <p>
        De gasque van gisteren was mijn tweede gasque. De eerste gasque waar ik geweest ben, was twee weken geleden en die was heel erg bijzonder. Dat was namelijk de 
        reccegasque van GH. Dat is dé gasque voor de <span data-tip="Zweedse woord voor de nieuwe eerstejaars studenten" className='Tooltip_Word'>recentiors</span>. 
        Iedere nation had zijn reccegasque op dezelfde dag: 19 februari. Vóór deze gasque, die om 17:00 begon, was er een openingsceremonie in het universiteitsgebouw om 
        ons als nieuwe studenten welkom te heten bij de universiteit en het studentenleven.
      </p>

      <p>
        Deze ceremonie was heel erg traditioneel en bijzonder om mee te maken. Vanuit elke nation vertrok een groep nieuwe studenten met het bestuur naar het traditionele 
        universiteitsgebouw. We liepen op het midden van de straat met een vlag van de nation voorop. Wij liepen samen met twee andere nations: <i>Västgöta</i> en <i>Östgöta</i>. VG had iemand mee die drumde en met veel kabaal liepen wij dus naar het universiteitsgebouw.
      </p>
      <p>
        Eenmaal daar aangekomen, moesten wij wachten tot alle nations zich hadden verzameld en toen werden wij per nation een voor een naar binnen geroepen. Terwijl iedereen 
        toekeek liepen wij de trappen van het gebouw op, binnen stonden mensen aan weerskanten van het pad om ons welkom te heten en werden wij door één van hen naar onze 
        plek geleid. Het voelde bijna alsof we de koninklijke familie waren. 
      </p>

      <div className="slide-container">
        <Slide {...customArrows} duration={10000} indicators={true}>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={gasque4} alt='Op weg naar de ceremonie'/>
            <p className="subtitle">Op weg naar de ceremonie</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={gasque5} alt='Alle nations verzamelen!'/>
            <p className="subtitle">Alle nations verzamelen!</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={gasque6} alt='Het binnentreden van het universiteitsgebouw'/>
            <p className="subtitle">Het binnentreden van het universiteitsgebouw</p>
          </div>
        </Slide>
      </div>

      <h3>Wauw-ervaring</h3>
      <p>
        Het universiteitsgebouw is echt prachtig. De ceremonie was in de grote zaal, hier worden al eeuwenlang studenten welkom geheten en diploma-uitreikingen gehouden. 
        Op het podium stond al een orkest klaar. Toen alle nations binnen waren, begon de ceremonie. Onder begeleiding van het orkest traden twee om twee alle vlaggendragers 
        van de nations binnen. Daarna volgden alle belangrijke mensen, zoals de rector en verschillende besturen.
      </p>
      <p>
        De hele ceremonie was in het Zweeds, maar wij kregen een boekje met de Engelse vertaling zodat wij het toch een beetje konden volgen. De ceremonie bestond uit 
        verschillende speeches, een optreden van het koor en een ‘prijsuitreiking’ voor de meest actieve nieuwe studenten van de nations van vorig semester. De ceremonie 
        was echt een ‘wauw-ervaring’, alles was zo officieel en traditioneel. Het is ook bijzonder dat dit al jaren zo gedaan wordt en ik daar dan nu ook onderdeel van uitmaak. 
      </p>
      <p>
        Na deze ceremonie werden wij weer per nation uit de zaal geleid en vervolgden wij onze weg naar GH. Bij GH begon toen onze reccegasque! Tijdens deze gasque 
        trad het koor van de nation op, renden wij in de pauze naar de shotjesbar (want dat is traditie) en zongen we natuurlijk veel Zweedse liedjes. Na afloop hadden we 
        de <i>släpp</i> (afterparty) bij <i>Östgöta</i> samen met <i>Västgöta</i>. Ik heb weer veel nieuwe mensen ontmoet en het heel gezellig gehad.
      </p>

      <div className="slide-container">
        <Slide {...customArrows} duration={10000} indicators={true} autoplay={false}>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={gasque7} alt='Begin van de ceremonie'/>
            <p className="subtitle">Begin van de ceremonie</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={gasque8} alt="De 'prijsuitreiking'"/>
            <p className="subtitle">De 'prijsuitreiking'</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={gasque9} alt='Voorgerecht bij de reccegasque van GH'/>
            <p className="subtitle">Voorgerecht bij de reccegasque van GH</p>
          </div>
          <div className='each-slide imgDiv SlideDiv'>
            <img src={gasque10} alt='Optreden van het koor tijdens de reccegasque van GH'/>
            <p className="subtitle">Optreden van het koor tijdens de reccegasque van GH</p>
          </div>
        </Slide>
      </div>

      <p>
        Ik eindig deze blog schrijvend op het balkon van <i>Gotland nation</i>. Het is nu zondag en ik zit koffiedrinkend te genieten van de zon. Ik ben weer met Tabea en 
        Kajsa en nu ook met Alex (Zweeds/Duits). Ik ga vanavond waarschijnlijk spelletjes spelen met huisgenoten. Ik heb ook heel veel zin in volgende week, ik ga 
        donderdagavond namelijk naar Lapland! Daarover snel meer! 
      </p>

      <div className='imgDiv'>
        <img src={gasque11} alt="Op het balkon aan de koffie bij Gotland"/>
        <p className="subtitle">Op het balkon aan de koffie bij Gotland</p>
        <hr className='small' />
        <img src={gasque12} alt='Op het balkon met Tabea (midden) en Kajsa (rechts)'/>
        <p className="subtitle">Op het balkon met Tabea (midden) en Kajsa (rechts)</p>
      </div>
    
      <p>
        Ik hou jullie op de hoogte!
      </p>
      
      <p>Hej då, <br />Babette </p>
      <div className="prevNextButs">
        <Link id='nextBlog' className='Link' to="/blogs/blog_19-3-22"> Volgende Blog: <br /><span>Li-La-Lapland (Deel 1)</span> </Link>
        <Link id='prevBlog' className='Link' to="/blogs/blog_1-2-22"> Vorige Blog: <br /><span>Een nation hier en een nation daar</span></Link>  
      </div>

      <ReactTooltip offset={{top: -10}} effect='solid'/>
    </div>
  );
}

export default Blog4;