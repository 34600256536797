import '../Page.css';
import './Blog.css';

import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';

import koffer from "../../img/Posts/Post1_1.jpg"
import zweeds from "../../img/Posts/Post1_2.jpg"

const Title = "1 week vóór vertrek: Bestellen, stressen en Zweeds leren";

function Blog1() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id='Blog_9-1-22' className="Blog Page">
      <Helmet>
        <title>{Title}</title>
      </Helmet>

      <h1>{Title}</h1>
      <h3 className='Date'>9 januari 2022</h3>
      <hr/>
      <p>Over iets minder dan een week is het na lang wachten dan eindelijk zo ver: Ik ga op uitwisseling naar Zweden! Ik ga naar Uppsala, een relatief kleine 
        studentenstad net boven Stockholm. Ik heb hier lang op moeten wachten door de inmiddels bekende spelbreker, Corona. Deze reis stond namelijk oorspronkelijk 
        voor september 2020 gepland, maar anderhalf jaar later <span style={{fontStyle:"italic", fontWeight:'600'}}>lijkt</span> hij dan toch echt door te gaan!
      </p>
      <p>
        Ik schrijf ‘lijkt’, want mijn vertrek is nog steeds niet helemaal zeker. Mocht het Nederlandse ministerie van Buitenlandse Zaken Zweden een oranje reisadvies geven, 
        dan wordt mijn uitwisseling sterk afgeraden. De reisverzekering dekt dan niets meer en ook de Universiteit van Amsterdam (UvA) trekt dan haar handen van mij af. Maar 
        voorlopig is het reisadvies voor Zweden nog geel, dus duimen dat dat zo blijft!
      </p>
      
      <p>De eerste week van 2022 stond voor mij in het teken van regelen, bestellen en organiseren. Elke dag bedacht (en bedenk) ik wel nieuwe dingen die nog moeten 
        gebeuren voor mijn vertrek. In deze blogpost beschrijf ik de zaken waar ik me de afgelopen week mee bezig heb gehouden. Allereerst een inkijkje in mijn to-do 
        list deze week:</p>

      <h3>To-Do-list</h3>
      <ul className='Checklist'>
        <li>Skibroek bestellen</li>
        <li>Thermo-ondergoed bestellen</li>
        <li>Schoenen bestellen</li>
        <li>Nieuwe ID kaart ophalen</li>
        <li>Reisverzekering afsluiten</li>
        <li>Koffer bestellen</li>
        <li>Winterjas naar de klerenmaker brengen</li>
        <li>Testafspraak voor reis maken</li>
        <li style={{backgroundImage: "none"}} >En ga zo nog maar even door…</li>
      </ul>
      
      <p>Zoals te zien, moest ik vooral veel bestellen deze week. Ik ben niet echt een onlineshopper, maar met de gesloten winkels, zat er voor mij niet echt wat anders op. 
        Op het moment van schrijven is het in Uppsala namelijk -12 graden Celsius en ligt er sneeuw. Heel leuk, maar ook heel koud. Mijn wintergarderobe is helaas niet zo 
        groot en moest ik daarom voor vertrek nodig aanvullen om onderkoeling te voorkomen. Dus ging ik met mijn weinige kennis over winterkleding het internet op deze week.</p>

      <h3>Jeuk?</h3>
      <p>Van mensen uit mijn omgeving had ik al begrepen dat merino wollen ondergoed echt een must is in de kou. Maar welke prijs is dan normaal voor dat soort wol? En welke 
        maat moet ik hebben? Moet dat strak zitten of liever iets losser? Is het normaal dat het jeukt? Met die vragen heb ik deze week geworsteld. Ik ben geen ster in keuzes 
        maken (iets wat ongetwijfeld snel duidelijk wordt in deze blog), dus dat was met vlagen best wel stressvol.</p>
      
      <p>Uiteindelijk heb ik via <a href="https://www.dilling.nl/" target="_blank" rel="noreferrer">Dilling</a> (een duurzaam bedrijf, dat was een pluspunt) een coltrui, legging en sokken van merino wol 
      besteld, in totaal kostte het rond de 100 euro. Dat vond ik best duur, maar als je het vergelijkt met andere websites, dan is dit nog aan de goedkope kant. Het jeukt 
      nu nog wel een beetje en misschien heb ik meer nodig, maar het is wel warm, dus dat is voor nu het belangrijkste! </p>
      
      <p>Daarnaast hadden wij thuis geen grote koffer en gezien de hoeveelheid die ik normaal gesproken voor één week vakantie meeneem, was een grote koffer wel wenselijk. 
        Mijn kofferkennis was (en is) best wel beperkt en er is echt veel keuze. Ik heb drie keer op het punt gestaan een koffer te kopen en het was drie keer een andere 
        koffer. Uiteindelijk, nadat drie mensen hebben gezegd: <span className='quote'>Koop nou gewoon die koffer Babette, die is gewoon goed!</span>, heb ik dan toch 
        een koffer besteld. En ik ben er tot nu toe erg blij mee! Zie onderstaande foto. </p>
        <div className='imgDiv'>
        <img id='koffer' src={koffer} alt="Ik met mijn koffer" />
        <p className="subtitle">Ik met mijn gloednieuwe koffer nog in de beschermfolie</p>
      </div>
      

      <h3>“Ik spreek geen Zweeds”</h3>
      <p>Tussen al het regelen en bestellen door, heb ik ook nog een aantal keer de <a href="https://www.duolingo.com/" target="_blank" rel="noreferrer">DuoLingo app</a> geopend. Met deze app probeer ik 
      Zweeds te leren en het is echt een aanrader als je een taal wilt leren. Door de app leer je de taal lezen, schrijven, spreken en verstaan. Ik kan inmiddels een paar 
      basisdingen zeggen, zoals <i>jag talar inte Svenska</i> (ik spreek geen Zweeds) en <i>tack så mycket</i> (Heel erg bedankt). Het is nog niet veel, maar het is een begin! </p>
      <div className='imgDiv'>
        <img id='zweeds' src={zweeds} alt="It's raining men in het zweeds" />
        <p className="subtitle">Een voorbeeld van een zin die ik moest leren in de app, handig dat ik dit nu ook in het Zweeds kan zingen</p>
      </div>
      <p>Naast alle noodzakelijk dingen, is er ook nog de noodzaak om afscheid te nemen van de mensen hier die ik vijf maanden niet ga zien. Het is toch fijn om hen even 
        te zien voordat ik ga, maar dat vereist nog het nodige organisatiewerk. Sowieso bemoeilijkt corona deze ontmoetingen. Sommige vrienden zitten in quarantaine, niet 
        iedereen kan ik tegelijkertijd zien en is het ook fijn om een beetje afstand te kunnen houden. Om Zweden in te kunnen, heb ik een negatieve test nodig, dus ik ben 
        erg voorzichtig. Dit levert allemaal de nodige stress op, maar het is het uiteindelijk wel waard. </p>
      
      <p>Want ondanks de stress en de zenuwen heb ik wel heel veel zin om te vertrekken! Op Instagram zie ik mooie foto’s van Uppsala in de sneeuw, ontdek ik steeds meer 
        leuke dingen om in Uppsala te doen en op Whatsapp krijg ik enthousiaste (maar ook wel gestresste) appjes van andere internationale studenten die ook naar Uppsala 
        komen (of daar al zijn). Hier word ik allemaal erg enthousiast van en ik heb dan ook erg veel zin om daar over een week aan te komen. </p>
      
      <p>Maar zover is het voor nu nog niet! Nu blijf ik nog even regelen, bestellen en organiseren en ga ik vanaf nu vooral ook inpakken en afscheid nemen. Ik ben er bijna!</p>
      
      <p>Ik hou jullie op de hoogte!</p>
      
      <p>Hej då, <br />Babette </p>
      <div className="prevNextButs">
        <Link id='nextBlog' className='Link' to="/blogs/blog_20-1-22"> Volgende Blog: <br /><span>Een groen begin</span></Link>
      </div>
    </div>
  );
}

export default Blog1;