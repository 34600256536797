import React from 'react'
import { Link } from 'react-router-dom'

export default function BlogIntro(props) {
  return (
    <div>
      <Link className='introBox' to={props.link}>
        <div className='intro'>
          <h3>{props.title}</h3>
          <h4>{props.date}</h4>
          <p>{props.txt} <a href className='Link'>Lees meer</a></p>
        </div>
      </Link>
      <hr />
    </div>
  )
}
