import './Page.css';

import BlogIntro from './Components/Blog-intro';

import React from 'react';
import { Helmet } from "react-helmet";

function Blogs() {
  return (
    <div id='Blogs' className="Page">
      <Helmet>
        <title>Blogs - Babette Blogt!</title>
      </Helmet>
      <h1>Blogs</h1>
      <hr />
      
      <BlogIntro 
        title="6x Zweden missen - Editie: Dagelijks leven"
        date="28 / 09 / 2022"
        txt="Ik ben inmiddels alweer drie maanden terug uit Zweden. Ik ben een nieuwe studie gestart en verhuisd naar Nijmegen. Hoewel het me goed doet om hier weer met 
        iets nieuws te beginnen, kan ik het niet helpen dat ik Zweden nog vaak mis.... "
        link="Blog_28-9-22"/>

      <BlogIntro 
        title="Ik leef nog!"
        date="31 / 05 / 2022"
        txt="Daar ben ik dan eindelijk weer! Het is inmiddels meer dan twee maanden geleden dat ik voor het laatst iets van me heb laten horen. Maar het gezegde is waar: 
        Geen bericht, is goed bericht.... "
        link="blog_31-5-22"/>

      <BlogIntro 
        title="Li-La-Lapland (Deel 1)"
        date="19 / 03 / 2022"
        txt="Van een sneeuwscooter besturen, naar afkoelen in een ijskoud meer na de sauna, tot het noorderlicht zien. Afgelopen weekend heb ik een van de meest bijzondere 
      weekenden van mijn leven meegemaakt.... "
        link="blog_19-3-22"/>

      <BlogIntro
        title="Eat, sing, drink, repeat"
        date="06 / 03 / 2022"
        txt="Het is zaterdagmiddag. Ik zit in café Linné in het centrum van Uppsala en ik ben herstellende van de gasque van gisteravond. Ik ben samen met Tabea (Duits) 
        en Kajsa (Deens). Ik heb net een luxe tosti op, die voor Nederlandse begrippen... "
        link="blog_6-3-22"
      />

      <BlogIntro
        title="Een nation hier en een nation daar"
        date="01 / 02 / 2022"
        txt="Daar ben ik dan eindelijk weer! Ik zit hier nu inmiddels meer dan twee weken en de tijd is voorbij gevlogen! Ik heb echt tijd vrij moeten maken om deze blog 
        te gaan schrijven, want mijn dagen zitten goed vol gepland. Met bijvoorbeeld... "
        link="blog_1-2-22"
      />

      <BlogIntro
        title="Een groen begin"
        date="20 / 01 / 2022"
        txt="Na een emotioneel afscheid op Schiphol, veel wind bij de landing en een lange zoektocht naar de welkomstdesk op Arlanda Airport, ben ik dan (eindelijk) in 
        Uppsala! Inmiddels zit ik... "
        link="blog_20-1-22"
      />

      <BlogIntro
        title="1 week vóór vertrek: Bestellen, stressen en Zweeds leren"
        date="09 / 01 / 2022"
        txt="Over iets minder dan een week is het na lang wachten dan eindelijk zo ver: Ik ga op uitwisseling naar Zweden! Ik ga naar Uppsala, een relatief kleine 
        studentenstad net boven Stockholm. Ik heb hier lang op moeten wachten door... "
        link="blog_9-1-22"
      />

      <br />
    </div>
  );
}

export default Blogs;