import './Welcome.css';
import './Components/Creativity/countdown.css';
import bab from '../img/bab.gif';

import { Helmet } from 'react-helmet';



function Welcome() {

  return (
    <div className="Welcome">
      <Helmet>
        <title>Babette Blogt!</title>
      </Helmet>
      <script src="https://unpkg.com/react/umd/react.production.min.js"></script>
      <div className="logo">
        <img src={bab} className="txt" alt="logo" />
      </div>
      <h1 className='Countdown_text'>Ik ben weer thuis!</h1>
      <br />
      <div>
        <h1 className='Countdown_text'>Hoe lang was ik in Zweden?</h1>
        <div className='Countdown'>
          <div>
            <p className='Number'>168</p>
            <p className='Text'>Dagen</p>
          </div>
          <p className='Colon'>:</p>
          <div>
            <p className='Number'>01</p>
            <p className='Text'>Uur</p>
          </div>
          <p className='Colon'>:</p>
          <div>
            <p className='Number'>6</p>
            <p className='Text'>Minuten</p>
          </div>
          <p className='Colon'>:</p>
          <div>
            <p className='Number'>35</p>
            <p className='Text'>Seconden</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;